/*TFA form*/
.TFAInputContainer {
  position: relative;

  .SwitchTFATypeContainer {
    margin-bottom: -20px;
  }

  .SwitchTFATypeButton {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
    z-index: 1;
    padding: 0;
    float: right;
    background: none;
    border: none;
    color: $MainColor1;
    font-size: 12px;
    position: relative;
  }

  .FormField .TFAHiddenField {
    opacity: 0.001;
  }

  .InputMask {
    height: 40px;
    pointer-events: none;
    position: absolute;
    top: 0;
    top: 23px;
    width: 100%;
  }

  .InputMask {
    background-color: $BasicColor5;
    border: 1px solid $BasicColor4;
    border-radius: 3px;
    display: flex;
    justify-content: space-around;

    &.white {
      background-color: white;
    }

    .Point {
      color: $BasicColor4;
      font-size: 24px;
      font-weight: 500;
      transform: translate(-5px, 2px);
      width: 1px;

      &.Filled {
        color: $BasicColor2;
        transform: translate(-5px, 2px);
      }
    }
  }
}

.RegistrationForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  margin-top: 60px;

  .RegisterFormWidth {
    position: relative;
    width: 100%;

    .FormBox {
      width: 100%;
    }
    .CustomSelect__menu {
      top: 100% !important;
      right: 0;
      left: 0;
    }
    .QrCodeContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .QrCode {
        width: 200px;
      }
    }
  }

  .RegisterFormButton {
    margin-top: 30px;
    width: 100%;
  }

  .SelectAccountType {
    display: flex;
    justify-content: space-between;
    width: 703px;
  }

  .RegistrationTile {
    border: 1px solid $BasicColor4;
    border-radius: 3px;
    cursor: pointer;
    width: 340px;
    position: relative;

    &.Selected {
      background-color: rgba($MainColor1, 0.25);
      border-color: $MainColor1;

      .RegistrationTileBottom {
        background-color: $MainColor1;
        border: none;

        .Checkmark {
          display: block;
        }
      }
    }
    .Recommended {
      background-color: $MainColor12;
      color: #fff;
      position: absolute;
      top: -1px;
      right: -1px;
      height: 30px;
      width: 116px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 3px 0 3px;
      font-size: 12px;
    }
  }

  .RegistrationTileTop {
    display: flex;
    flex-direction: column;
    padding: 23px;
    border-radius: 3px;
  }

  .RegistrationTileLabel {
    color: $MainColor1;
    font-size: 20px;
    margin-bottom: 29px;
  }

  .RegistrationTileImage {
    align-self: center;
    height: auto;
    width: 57%;
  }

  .RegistrationTileBottom {
    align-items: center;
    background-color: $BasicColor5;
    border-top: 1px solid $BasicColor4;
    display: flex;
    height: 41px;
    justify-content: center;
  }

  .CheckmarkContainer {
    background-color: white;
    border-radius: 15px;
    height: 30px;
    width: 30px;

    img {
      display: none;
      height: 30px;
      width: 30px;
    }
  }

  .SuccessMessage {
    width: 340px;
  }

  .TFAInputContainer {
    position: relative;

    .InputMask {
      height: 40px;
      pointer-events: none;
      position: absolute;
      top: 0;
      top: 23px;
      width: 100%;
    }
  }
}
.RegisterFormWidth {
  .Spinner {
    position: inherit;
    transform: none;
    background: none;
  }
  .PhoneInput {
    margin-bottom: 20px;
    .PhoneInputCountry {
      padding: 10px;
      background-color: $BasicColor5;
      border: 1px solid $BasicColor4;
      border-radius: 3px;
      height: 40px;
    }
    .PhoneInputCountryIcon {
      border-radius: 3px;
      img {
        border-radius: 3px;
      }
    }
    .PhoneInputInput {
      padding: 10px 15px;
      background-color: $BasicColor5;
      border: 1px solid $BasicColor4;
      border-radius: 3px;
      height: 40px;
      color: $BasicColor2;
    }
  }
  .LinkText {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    font-size: 12px;
    &.FloatRight {
      float: right;
      z-index: 1;
    }
  }
  .Btn {
    height: 40px;
    flex-direction: row;
    justify-content: center;
  }
  .Btn .Spinner {
    width: 50px;
    margin: 0 auto;
    transform: scale(0.3);
    margin-top: -20px;
  }
}

@media only screen and (max-width: 768px) {
  .RegistrationForm {
    .SelectAccountType {
      flex-direction: column;
      width: 100%;
      .RegistrationTile {
        width: 100%;
      }
      .RegistrationTile:first-of-type {
        margin-bottom: 20px;
      }
    }
    .RegisterFormWidth {
      width: 100%;
    }
  }
}
