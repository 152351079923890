/* Basics */
@media only screen and (max-width: 1200px) {
  #root {
    flex-direction: column;
  }
  .WidthContent {
    padding-left: 50px;
    padding-right: 50px;
  }
  .HeightContent {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .WidthContent {
    padding-left: 40px;
    padding-right: 40px;
  }
  .HeightContent {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .WidthContent {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .WidthContent,
  .InnerWidthContent {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 360px) {
  .WidthContent,
  .InnerWidthContent {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Mobile Header */
@media only screen and (max-width: 1200px) {
  .Main {
    width: 100%;
  }
  .MainHeader {
    display: none;
  }
  .MobileHeader {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;

    .User {
      justify-self: flex-end;
      display: flex;
      align-items: center;

      .MenuIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        color: $BasicColor1;
        background-color: $MainColor1;
        border-radius: 50%;
        cursor: pointer;

        span {
          margin: 0;
        }
      }
    }
  }
}

/* Navigation */
@media only screen and (max-width: 1200px) {
  .Navigation {
    opacity: 0;
    z-index: 100;
    top: 0px;
    position: fixed;
    left: -240px;
    background: none;
    transition: 0.3s;
    .Inner {
      height: 100vh;
      top: 0;
    }
  }
  .Navigation.Open {
    opacity: 1;
    display: flex;
    left: 0;
  }
}

/* Footer */
@media only screen and (max-width: 768px) {
  footer {
    justify-content: center;
  }
}

/* Forms */
@media only screen and (max-width: 768px) {
  .FormSideBySide {
    display: block;
  }
  .FormSideBySide .FormLabel {
    display: block;
    width: 100%;
    padding: 0px;
  }
  .FormSideBySide .FormField {
    display: block;
    .EditBox.ViewOnly {
      padding-top: 0;
      padding-bottom: 15px;
    }
  }
  .FormSideBySide .FieldView {
    display: block;
  }
}

/* Right Menu */
@media only screen and (max-width: 768px) {
  .RightMenu {
    position: fixed;
    display: none;
    bottom: 0;
    z-index: 30;
    border-top: 1px solid $MainColor5;
    width: 100vw;
    overflow-y: auto;
    height: 0;
    border-left: none !important;
    right: 0;
  }
  .RightMenu.entered {
    display: flex;
    height: auto;
  }
  .RightMenu .ItemsContainer {
    position: relative;
    padding: 20px 0px 20px 10px;
  }
}

/* Login */
@media only screen and (max-width: 768px) {
  .LoginForm {
    padding: 40px 30px;
  }
}
@media only screen and (max-width: 480px) {
  .LoginForm {
    padding: 40px 20px;
  }
}
@media only screen and (max-width: 360px) {
  .LoginForm {
    padding: 40px 10px;
  }
}

/* Right Panel */
@media only screen and (max-width: 768px) {
  .RightPanel {
    min-width: 400px;
  }
  .RightPanel .Buttons {
    justify-content: center;
    padding: 0px 25px;
  }
  .RightPanel .Buttons .Btn {
    flex: 1;
    margin: 0px 5px;
    min-width: inherit;
  }
}
@media only screen and (max-width: 480px) {
  .RightPanel {
    min-width: 300px;
  }
  .RightPanel .Top {
    padding: 10px 60px 10px 20px;
  }
  .RightPanel .Bottom {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 360px) {
  .RightPanel .Top {
    padding: 10px 60px 10px 10px;
  }
  .RightPanel .Buttons {
    padding: 0px 5px;
  }
}

/* Modal */
@media only screen and (max-width: 768px) {
  .ModalUnderlay .Modal {
    width: calc(100% - 20px);
  }
  .ModalUnderlay .Modal .Inner {
    width: 100%;
  }
  .Modal .Buttons {
    justify-content: center;
    padding: 0px 25px;
  }
  .Modal .Buttons .Btn {
    flex: 1;
    margin: 0px 5px;
    min-width: inherit;
  }
}
@media only screen and (max-width: 480px) {
  .Modal .Top {
    padding: 10px 60px 10px 20px;
  }
  .Modal .Block {
    padding: 40px 20px 20px 20px;
  }
  .Modal .Buttons {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 360px) {
  .Modal .Top {
    padding: 10px 60px 10px 10px;
  }
  .Modal .Block {
    padding: 40px 10px 20px 10px;
  }
  .Modal .Buttons {
    padding: 0px 5px;
  }
}

/* Page Header */
@media only screen and (max-width: 768px) {
  .PageHeader {
    flex-direction: column;
    align-items: flex-start;
  }
  .PageHeader .HeaderActionsContainer {
    padding-top: 20px;
  }
}

/* Pagination */
@media only screen and (max-width: 768px) {
  .PageHeader .HeaderActionsContainer {
    flex-direction: column;
    align-items: center;

    .SearchBoxContainer {
      margin: 0 0 0.5rem 0;
      width: 100%;
    }
    .Buttons {
      width: 100%;
      .Btn {
        width: 100%;
      }
    }
  }

  .TableNavigation {
    flex-flow: column;

    .PaginatorButtons {
      margin-bottom: 10px;
    }

    .PaginatorInfo {
      text-align: center;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .TableNavigation {
    .PaginatorButtons {
      flex-direction: column;
      .PaginationButtonsLeft {
        display: none;
      }
      .PaginationButtonsLeft.Mobile {
        display: block;
        margin-right: 1rem;
        order: 0;
      }

      .PageNumber {
        order: 1;
        padding: 10px !important;

        .PageNumberInput {
          .FormBox {
            margin-right: 10px;
          }

          input {
            margin: 0px 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .TableNavigation {
    .PaginatorButtons {
      .PaginatorBtn.First {
        margin-right: 5px;
      }

      .PaginatorBtn.Last {
        margin-left: 5px;
      }

      .PageNumber {
        padding: 5px !important;

        .PageNumberInput {
          .FormBox {
            margin-right: 5px;
          }

          input {
            margin: 0px 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .FlexTableContainer {
    max-height: calc(100% - 220px);
  }
}

@media only screen and (max-width: 768px) {
  .FlexTableContainer {
    height: auto;
  }
}
