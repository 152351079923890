.BtnGroup {
  margin-top: -10px;
  margin-bottom: 30px;
  .Btn {
    margin-right: 10px;
    font-size: 1.25em;
    padding: 8px 16px;
  }
  .NoFile,
  .NoFile:hover {
    opacity: 0.5;
    cursor: auto;
  }
}
.FileEl {
  // .FormSideBySide .FormLabel,
  // .FormLabel + .FormField .EditBox.ViewOnly {
  //   padding-top: 0;
  // }
  .FileElHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.InnerWidthContent.VerifyBusiness {
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: auto;
  height: calc(100vh - 120px);
  width: 85vw;
}
.InnerWidthContent.VerifyBusiness.errMsg {
  height: calc(100vh - 150px);
}
.SignificantParties .DocumentSection {
  padding: 0;
  border: none;
}
.ContentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  .Col1 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  div {
    .FormSection {
      border-radius: 3px;
      border: 1px solid $MainColor5;
      margin-bottom: 10px;
      min-width: 340px;
      .Header {
        display: flex;
        align-content: center;
        justify-content: space-between;
        padding: 5px 0px 5px 15px;
        flex-wrap: wrap;
        &:hover {
          cursor: pointer;
        }
        &.Expand {
          border-bottom: 1px solid $MainColor5;
        }
        h4 {
          margin-bottom: 0px;
          color: black;
          font-weight: bold;
          align-self: center;
        }
      }
      .Content {
        padding: 20px;
        padding-top: 10px;
        .FormBox {
          margin-bottom: 0px;
        }
        h4:first-child {
          margin: 0px;
        }
        h4 {
          margin-bottom: 0px;
          margin-top: 1rem;
        }
      }
    }
    .FormSection:last-child {
      margin-bottom: 0px;
    }
  }
}
.FormSideBySide.Next {
  display: block;
  .FormField {
    display: block;
    position: unset;
    .EditBox.ViewOnly {
      padding-top: 5px;
    }
  }
}

.Tick {
  width: 20px;
  height: 20px;
  align-self: center;
}

.Chevron {
  height: 35px;
  width: 35px;
  align-self: center;
  transform: rotate(180deg);
  &:hover {
    cursor: pointer;
  }
}
.Chevron.ChevronExpand {
  transform: rotate(270deg);
}
@media screen and (max-width: 1200px) {
  .ContentContainer {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .FormSideBySide {
    .FormLabel {
      margin: 0px;
    }
    .FormLabel + .FormField {
      .EditBox {
        &.ViewOnly {
          margin-bottom: 0px;
          padding: 5px 0px 10px 15px;
        }
      }
    }
  }
  h4 {
    max-width: 250px;
  }
}

@media screen and (max-width: 480px) {
  .InnerWidthContent.VerifyBusiness {
    width: 100vw;
  }
  h4 {
    max-width: 220px;
  }
  .Col1 {
    min-width: 250px;
  }
  .Col2 {
    min-width: 250px;
  }
}
@media screen and (max-width: 360px) {
}
