/* Pagination */
.Pagination {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Pagination .Inner {
  margin-top: 20px;
  display: flex;
  align-content: center;
  align-items: center;
}

.Pagination .Inner button:first-child {
  margin-right: 5px;
}
.Pagination .Inner button:last-child {
  margin-left: 5px;
}

.Pagination .Inner .Info {
  font-size: 1.3em;
  text-align: center;
  padding: 0 20px;
}

.TableNavigation {
  padding: 30px 0 0;
  min-height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  //z-index: -1;
}
.PaginationButtonsMobile {
  display: flex;
}
.PaginationButtonsLeft.Mobile {
  display: none;
}
.TableNavigation .PaginatorBlank {
  width: 25%;
}
.TableNavigation .PaginatorButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 50%;
  .PaginatorBtn {
    height: 30px;
    width: 30px;
    border-radius: 3px;
    border: 1px solid $BasicColor4;
    background-color: $BasicColor1;
    cursor: pointer;

    &:disabled {
      opacity: 0.4;
      cursor: auto;
    }

    img {
      height: 30px;
      width: 30px;
    }
  }

  .First {
    margin-right: 10px;
  }

  .Last {
    margin-left: 10px;
  }

  .PageNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    font-size: 1.1em;
    color: $MainColor3;
    text-align: center;

    .PageNumberInput {
      display: flex;
      align-items: center;
      .FormBox {
        margin: 0px;
        margin-right: 15px;
        .FormField {
          .DropDown {
            min-width: 55px;
            height: 30px;
            width: 55px;
            padding: 0px 8px;
            font-size: 1em;
          }
        }
      }

      input {
        height: 30px;
        width: 30px;
        background-color: $BasicColor5;
        border-radius: 3px;
        border: 1px solid $BasicColor4;
        font-family: $Font1;
        margin: 0px 15px;
        text-align: center;
        font-size: 1em;
      }
    }
  }
}
.TableNavigation .PaginatorInfo {
  font-size: 1.1em;
  text-align: right;
  color: $MainColor3;
}

/* Items Per Page */
.ItemsPerPage .Inner .Items {
  font-size: 1.3em;
  min-width: 100px;
}
