.CustomSelect {
  background-color: white;
  width: 100%;
  min-width: 150px;
  font-size: 0.8rem;

  &.CustomSelect--is-disabled {
    opacity: 0.5;
  }

  &.Small {
    max-width: 100px;
    width: 100%;
    font-size: 0.7rem;

    .CustomSelect__indicators {
      .CustomSelect__indicator {
        width: 15px;
        padding: 0;
        margin-right: 5px;
      }
    }
    .CustomSelect__control .CustomSelect__value-container {
      padding-left: 5px;
    }
  }

  &.RoundedRect {
    border-radius: 3px;

    .CustomSelect__control {
      width: 100%;
      padding: 0;
      border-radius: 3px;
      min-height: 30px;
      .CustomSelect__value-container {
        .CustomSelect__placeholder,
        .CustomSelect__single-value {
          font-size: 12px;
          color: $BasicColor2;
          margin-left: 0;
          + div {
            margin: 0;
          } // to remove margin from the input indicator, since we cannot target with CustomSelect__ prefix.
        }
      }
    }
  }

  .CustomSelect__control {
    width: 100%;
    padding: 2.5px 10px;
    background-color: $BasicColor5;
    border-radius: 44px;
    border-color: $BasicColor4;
    cursor: pointer;

    .CustomSelect__indicators {
      .CustomSelect__indicator-separator {
        display: none;
        position: absolute;
        visibility: hidden;
      }
    }

    .CustomSelect__value-container {
      padding: 0 0 0 15px;
    }
  }

  .CustomSelect__menu {
    border-radius: 3px;
    padding: 10px 0px;
    border: none;
    -webkit-box-shadow: 0px 0px 4px -2px $MainColor7;
    -moz-box-shadow: 0px 0px 4px -2px $MainColor7;
    box-shadow: 0px 0px 4px -2px $MainColor7;
    z-index: 20;

    .CustomSelect__menu-list {
      .CustomSelect__option {
        cursor: pointer;
        padding: 10px;
        color: $BasicColor2;
      }
      .CustomSelect__option--is-selected {
        background-color: $MainColor1;
        color: $BasicColor1;
      }
      .CustomSelect__option--is-focused:not(.CustomSelect__option--is-selected) {
        background-color: $MainColor8;
        color: $BasicColor2;
      }
    }
  }
}
