footer {
  width: 100%;
  height: 60px;
  position: fixed !important;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $BasicColor1;
  font-size: 0.8em;
  color: $BasicColor3;
  border-top: 1px solid $BasicColor4;

  .Underlined {
    text-decoration: underline;
  }
}
