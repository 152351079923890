.ColoredBox {
  padding: 3px 5px;
  color: $BasicColor1;
  border-radius: 4px;
  font-weight: 600;

  &.Green {
    background-color: $Colour_Great;
  }
  &.Amber {
    background-color: $Colour_SoSo;
  }
  &.Red {
    background-color: $Colour_Weak;
  }
}

.ReportSummary {
  flex: 1;
  background-color: $BasicColor5;
  border-radius: 3px;
  padding: 20px;
  max-width: 522px;

  h4 {
    text-transform: none;
    margin-bottom: 0px;
  }

  div {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $BasicColor4;
    span {
      &:first-child {
        width: 150px;
      }
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
  .Address {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: fit-content;
    margin-top: 10px;
    font-size: 1.3em;
    .div {
      border: none;
    }
  }
  .AddressSection {
    height: fit-content;
  }
}

.ClientProfile {
  flex: 1;
  border: 1px solid $BasicColor4;
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;

  h4 {
    text-transform: none;
    margin-bottom: 0px;
  }
  .ClientProfileHeader {
    display: flex;
    margin-top: 10px;

    p {
      margin-bottom: 10px;
    }
  }
}

.DocumentSection,
.AddressInfo,
.AdminInfo,
.ScreeningSection {
  width: 100%;
  border: 1px solid $BasicColor4;
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;

  h4 {
    text-transform: none;
    margin-bottom: 0px;
  }
  h5 {
    padding-top: 15px;
    border-top: 1px solid $BasicColor4;
    font-size: 1.2em;
  }
}

.BiomatricSection {
  width: 100%;
  border: 1px solid $BasicColor4;
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;

  h4 {
    text-transform: none;
  }
  span {
    font-size: 0.8em;
    font-weight: 600;
  }

  .Biometrics {
    display: flex;
    margin-top: 20px;

    .IDPhoto {
      width: 100px;
      display: flex;
      flex-direction: column;
      margin-right: 30px;
    }
  }
}

.ProfileImage {
  max-height: 150px;
  max-width: 100px;
  cursor: pointer;
  margin-right: 15px;
}

.SummaryImage {
  align-self: unset;
  max-height: 200px;
  max-width: calc(min(330px, 100%));
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.ImagePopout {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  background-color: $BasicColor2 !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 10 !important;

  > img {
    max-width: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
  }
}

.ViewTable {
  width: 100%;

  thead,
  tbody {
    tr {
      th {
        height: 40px;
        border-bottom: 1px solid $BasicColor4;
        text-align: start;
        text-align: left;
        vertical-align: middle;
      }

      td {
        vertical-align: middle;
        padding: 10px 0;
        border-bottom: 1px solid $BasicColor4;

        img {
          height: 15px;
        }

        &:first-child {
          font-weight: 600;
          color: $BasicColor3;
          width: 150px;
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}
