/* Constants */
// Table Cell constants
$miniCellWidth: 30px;
$smallMinCellWidth: 55px;
$semiSmallMinCellWidth: 70px;
$mediumMinCellWidth: 100px;
$semiLargeMinCellWidth: 150px;
$largeMinCellWidth: 200px;

// Table Head constants
$tableHeadFontSize: 0.7rem;
$tableHeadLetterSpacing: 0.05em;
$tableHeadCellHeight: 40px;
$tableHeadCellPaddingRight: 15px;

// Table Body constants
$tableBodyFontSize: 1.1em;
$tableBodyCellHeight: 40px;
$tableBodyCellPaddingRight: 15px;

// Misc
$RowSelectedColour: $MainColor2;

@mixin cell-sort-by-icon($iconUrl) {
  background-image: url($iconUrl);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin table-default-head {
  display: flex;
  justify-content: space-between;
}

@mixin table-head-default-cell {
  position: relative;
  display: flex;
  align-items: center;
  min-width: $mediumMinCellWidth;
  border-bottom: 1px solid $BasicColor4;
  font-size: $tableHeadFontSize;
  letter-spacing: $tableHeadLetterSpacing;
  height: $tableHeadCellHeight;
  flex: 1;
  padding-right: $tableHeadCellPaddingRight;
  color: $BasicColor2;
  overflow: hidden;
  text-transform: uppercase;

  &.Sortable {
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    span.Sorting {
      color: $MainColor1;
      font-weight: bold;
    }
  }

  .SortIcon {
    cursor: pointer;
    width: 15px;
    height: 20px;

    &.ASC {
      @include cell-sort-by-icon("../../assets/Icon_SortBy_03.png");
    }

    &.DESC {
      @include cell-sort-by-icon("../../assets/Icon_SortBy_02.png");
    }
  }

  &.Mini {
    min-width: $miniCellWidth;
    max-width: $miniCellWidth;
    padding-right: 0 !important;
    padding-left: 0;
  }

  &.Small {
    min-width: $smallMinCellWidth;
    padding-right: 10px;
  }

  &.SemiSmall {
    min-width: $semiSmallMinCellWidth;
    padding-right: 10px;
  }

  &.Large {
    min-width: $largeMinCellWidth !important;
  }

  &.SemiLarge {
    min-width: $semiLargeMinCellWidth !important;
  }

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  &.NoSortIcon {
    background-image: none;
    // padding-right: 0;
    span {
      padding-right: 0;
    }
  }

  &.NoPaddingRight {
    padding-right: 0;
  }

  &.CheckboxCell {
    background-image: none;
    min-width: 0;
    max-width: 40px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  &.FilterCell {
    background-image: none;
    min-width: 0;
    max-width: 50px;
    padding: 0 10px 0 20px;

    .FilterIcon {
      max-width: 18px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.RowOptionCell {
    background-image: none;
    min-width: 50px;
    max-width: 50px;
    padding: 0;
    overflow: visible;
  }
}

@mixin table-body-default-cell {
  position: relative;
  display: flex;
  align-items: center;
  min-width: $mediumMinCellWidth;
  font-size: $tableBodyFontSize;
  height: $tableBodyCellHeight;
  flex: 1;
  padding-right: $tableBodyCellPaddingRight;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &.Mini {
    min-width: $miniCellWidth;
    max-width: $miniCellWidth;
    padding-right: 0 !important;
    padding-left: 0 !important;
    justify-content: center;
    span {
      padding-right: 0 !important;
    }
  }

  &.Small {
    min-width: $smallMinCellWidth;
    padding-right: 10px;
  }

  &.SemiSmall {
    min-width: $semiSmallMinCellWidth;
    padding-right: 10px;
  }

  &.Large {
    min-width: $largeMinCellWidth !important;
  }

  &.SemiLarge {
    min-width: $semiLargeMinCellWidth !important;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  &.FilterCell {
    background-image: none;
    min-width: 0;
    max-width: 50px;
    padding: 0 10px;
    order: 0;
  }

  &.ButtonCell {
    position: relative;
    justify-content: center;
    white-space: nowrap;
    padding: 0 10px;
    max-width: 100px;

    .Btn {
      overflow: hidden;
      font-size: $tableBodyFontSize;
      text-overflow: ellipsis;
      padding: 4px 7px;
    }
  }

  &.NoPaddingRight {
    padding-right: 0;
  }

  &.CheckboxCell {
    background-image: none;
    min-width: 0;
    max-width: 40px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  &.RowOptionCell {
    min-width: 50px;
    max-width: 50px;
    padding: 0;
    order: 1;
    overflow: visible;

    .Icon {
      max-width: 100%;
      transition: all 0.2s ease-in-out;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@mixin table-body-default-row {
  display: flex;
  justify-content: space-between;
  color: $BasicColor3;
  font-size: 1.1;
  cursor: pointer;

  &:nth-child(even) {
    .Cell {
      background-color: $BasicColor5;
    }
  }

  &.Highlight .Cell {
    background-color: $MainColor8 !important;
  }

  &.ColourFilter_red .Cell {
    background-color: rgba(255, 0, 0, 0.7) !important;
  }

  &.ColourFilter_green .Cell {
    background-color: rgba(0, 128, 0, 0.7) !important;
  }

  &.ColourFilter_blue .Cell {
    background-color: rgba(0, 0, 255, 0.7) !important;
  }

  &.ColourFilter_purple .Cell {
    background-color: rgba(128, 0, 128, 0.7) !important;
  }

  &.ColourFilter_yellow .Cell {
    background-color: rgba(255, 255, 0, 0.7) !important;
  }

  &.ColourFilter_aqua .Cell {
    background-color: rgba(0, 255, 255, 0.7) !important;
  }

  &.NoHover {
    cursor: default;
  }
}

@mixin right-left-box-shadow {
  -webkit-box-shadow: inset 10px -30px 6px -6px $MainColor7,
    inset -10px -30px 6px -6px $MainColor7;
  -moz-box-shadow: inset 10px -30px 6px -6px $MainColor7,
    inset -10px -30px 6px -6px $MainColor7;
  box-shadow: inset 10px -30px 6px -6px $MainColor7,
    inset -10px -30px 6px -6px $MainColor7;
  z-index: 10;
}

/* Main Wrapper */
.FlexTableWrapper {
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 14px;
  * {
    box-sizing: border-box;
  }

  &.NoHover {
    .TableContainer .RightSideTable .TableBody,
    .TableContainer .LeftSideTable .TableBody .Row {
      cursor: default;
    }
  }

  &.LargeRow {
    .Cell {
      height: 60px !important;
    }
  }
}

.FlexTableWrapper .Inner {
  background: #ffffff;
  height: 100%;
}

.FlexTableWrapper .TableContainer {
  display: flex;
  width: 100%;
  height: calc(100% + 14px);
  overflow: auto;
}

.Head {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}
/* Left side table */

.LeftSideTable {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff;
  height: fit-content;
}

.FlexTableWrapper .TableContainer .LeftSideTable .Head {
  @include table-default-head();
}

.FlexTableWrapper .TableContainer .LeftSideTable .Head .Cell {
  @include table-head-default-cell();

  .SortIcon {
    @include cell-sort-by-icon("../../assets/Icon_SortBy_01.png");
  }

  &:first-child {
    padding-left: 20px;
  }

  span {
    padding-right: 20px;
  }
}

.FlexTableWrapper .TableContainer .LeftSideTable .TableBody .Row {
  @include table-body-default-row();

  &.Selected {
    .Cell {
      background-color: $RowSelectedColour;
    }
  }

  &.NoHover {
    cursor: default;
  }
}

.FlexTableWrapper .TableContainer .LeftSideTable .TableBody .Row .Cell {
  @include table-body-default-cell();

  &:first-child {
    padding-left: 20px;
  }

  &.BoldText {
    font-weight: bold;
  }

  &.GreyText {
    color: $MainColor3;
  }
}

/* Middle Table */

.FlexTableWrapper .TableContainer .MiddleTable {
  flex-grow: 1;
  height: fit-content;
}

.FlexTableWrapper .TableContainer .MiddleTable .Head {
  @include table-default-head();
}

.FlexTableWrapper .TableContainer .MiddleTable .Head .Cell {
  @include table-head-default-cell();

  .SortIcon {
    @include cell-sort-by-icon("../../assets/Icon_SortBy_01.png");
  }

  &.BoldText {
    font-weight: bold;
  }

  &.GreyText {
    color: $MainColor3;
  }
}
.FlexTableWrapper .TableContainer .MiddleTable .Head .Cell:first-child {
  padding-left: 20px;
  min-width: calc(#{$mediumMinCellWidth} + 20px);

  &.NoPaddingLeft {
    padding-left: 0;
  }
}

.FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row {
  @include table-body-default-row();
  &.Selected {
    .Cell {
      background-color: $RowSelectedColour;
    }
  }
}

.FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row .Cell {
  @include table-body-default-cell();

  &.BoldText {
    font-weight: bold;
  }

  &.GreyText {
    color: $MainColor3;
  }
}
.FlexTableWrapper
  .TableContainer
  .MiddleTable
  .TableBody
  .Row
  .Cell:first-child {
  padding-left: 20px;
  min-width: calc(#{$mediumMinCellWidth} + 20px);

  &.NoPaddingLeft {
    padding-left: 0;
  }
}

.RightTable {
  position: sticky;
  right: 0;
  z-index: 2;
  background-color: #fff;
  height: fit-content;
}

.FlexTableWrapper .TableContainer .RightTable .Head {
  @include table-default-head();
}

.FlexTableWrapper .TableContainer .RightTable .Head .Cell {
  @include table-head-default-cell();

  .SortIcon {
    @include cell-sort-by-icon("../../assets/Icon_SortBy_01.png");
  }
  // &:first-child {
  //   border-left: 1px solid $MainColor3;
  // }
}

.FlexTableWrapper .TableContainer .RightTable .TableBody .Row {
  @include table-body-default-row();
  color: $BasicColor2;

  &.Selected {
    .Cell {
      background-color: $RowSelectedColour;
    }
  }

  &.NoHover {
    cursor: default;
  }
}

.FlexTableWrapper .TableContainer .RightTable .TableBody .Row .Cell {
  @include table-body-default-cell();

  // &:first-child {
  //   border-left: 1px solid $MainColor3;
  // }

  &.BoldText {
    font-weight: bold;
  }

  &.GreyText {
    color: $MainColor3;
  }

  &:only-child {
    justify-content: center;
    padding-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .FlexTableWrapper {
    transition: none;
  }

  .FlexTableWrapper.CalculateWidth {
    max-width: calc(100vw - 100px);
  }

  .SmallerWidth {
    max-width: auto;

    .TableContainer .MiddleTable {
      overflow: auto;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .FlexTableWrapper.CalculateWidth {
    max-width: calc(100vw - 80px);
  }
}
@media only screen and (max-width: 768px) {
  .FlexTableWrapper.CalculateWidth {
    max-width: calc(100vw - 60px);
  }
}
@media only screen and (max-width: 480px) {
  .FlexTableWrapper.CalculateWidth {
    max-width: calc(100vw - 40px);
  }
}
@media only screen and (max-width: 360px) {
  .FlexTableWrapper.CalculateWidth {
    max-width: calc(100vw - 20px);
  }
}
