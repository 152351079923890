.RightPanel .StaticContent .Form {
  width: calc(
    100% + 1rem
  ); // address strange WYSIWYG bug: causing unnecessary scrollbar

  .FormField .FormField {
    width: 100%;
    display: flex;
  }

  .ck.ck-editor {
    width: 100%;
  }
}

.StaticContentView {
  padding: 10px 15px;
}
