.AccountPage.WidthContent {
  max-width: 620px;
  margin: 0;

  h4 {
    font-weight: 700;
  }
}
.LeftMarker {
  height: 100%;
  width: 5px;
  display: inline-block;
  vertical-align: middle;
}
.ActivePage .LeftMarker {
  background-color: $MainColor1;
}
.AccountSideBar {
  display: flex;
  width: 260px;

  ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    li {
      flex-direction: row;
      height: 50px;
      line-height: 49px;
      width: 100%;
      background-color: $BasicColor5;
      padding: 0;

      a {
        color: $BasicColor2;
        font-weight: 800;
        text-decoration: none;
        vertical-align: middle;
        padding: 0 20px;
        display: inline-block;
        height: 100%;
        width: calc(100% - 5px);
      }
    }
    .ActivePage {
      background-color: $BasicColor1;
    }
  }
}
.AccountSubPage {
  margin: 0 0 0 60px;
}
.AccountOverviewPage {
  .PageSubsection {
    margin-top: 30px;

    > * {
      margin-bottom: 15px;
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 800;
    letter-spacing: 1px;
  }
  a {
    font-size: 1.2em;
    color: $MainColor1;
  }
}

.ChangePasswordSection {
  .SideBySide {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;

    .FormField {
      position: relative;
      width: calc(100% - 130px);

      img {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    label {
      font-size: 1.2em;
      line-height: 40px;
    }
  }
  .TFASection {
    background-color: $MainColor2;
    margin-bottom: 20px;
    padding: 20px;
    .TFAInputContainer .InputMask {
      &.ErrorField {
        background-color: $AlertColor4;
      }
    }
    &.Hidden {
      display: none;
    }
  }
  .SuccessMessage {
    text-align: center;
    color: $GreenText;
    font-size: 1.3em;
    margin-bottom: 10px;
  }
}

.ChangeTFASection {
  margin-top: 20px;
  .TfaSelectBox {
    width: 100%;
    height: 70px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid $BasicColor4;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &.Active {
      background-color: $MainColor2;
      border: 1px solid $MainColor1;

      .Btn {
        background: $MainColor1;
        color: $BasicColor1;
      }
    }
    .TfaType {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    img {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      margin-left: 20px;
      font-weight: 800;
      font-size: 1.3em;
    }
    .Btn {
      background: $MainColor2;
      border: 1px solid $MainColor2;
      color: $MainColor1;
      padding: 12px 15px;
      font-size: 1.2em;
      float: right;
      margin-top: 6px;
      margin-right: 5px;
      border-radius: 20px;
    }
  }
}
.ScanQRForm {
  text-align: center;
  .QRCode {
    width: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .AccountPage.WidthContent {
    margin: 0 auto 0 auto;
    .SideBySide {
      flex-direction: column;
      label {
        line-height: normal;
        margin-bottom: 5px;
      }
      .FormField {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .ChangeTFASection .TfaSelectBox {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}
