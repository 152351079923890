.FormField .AddressRisk {
  padding-top: 15px;
  padding-left: 15px;
  h3 {
    font-size: 1.3em;
    margin-bottom: 5px;
  }
  p {
    margin-left: 15px;
    margin-bottom: 5px;
  }
  .RiskValue {
    color: $BasicColor1;
    border-radius: 3px;
    display: inline-block;
    min-width: 10px;
    text-align: center;
    font-weight: 600;
    padding: 0 3px;
    &.Green {
      background-color: $Colour_Great;
    }
    &.Orange {
      background-color: $Colour_SoSo;
    }
    &.Red {
      background-color: $Colour_Weak;
    }
  }
}
