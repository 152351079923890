/* Basics */
* {
  background-repeat: no-repeat;
  padding: 0px;
  margin: 0px;
  outline: none;
  font-family: $Font1;
  box-sizing: border-box;
}
img {
  border: 0px;
}
table,
tr,
td,
th {
  border-spacing: 0px;
  vertical-align: top;
}
td,
th {
  empty-cells: hide;
}
body {
  font-size: 62.5%;
  color: $BasicColor2;
  font-family: $Font1;
  background: $BasicColor1;
}
#root {
  display: flex;
  max-width: 100vw;
}
.Main {
  position: fixed;
  top: 60px;
  flex: 1 1;
  margin-left: 240px;
  width: calc(100vw - #{$navWidth});
  overflow-x: hidden; //??

  &.NoNav {
    margin-left: 0;
    width: 100vw;
  }

  @media all and (max-width: 1200px) {
    max-width: none;
    margin-left: 0;
  }
}

h1 {
  font-size: 3em;
  line-height: 1.3em;
  font-weight: 600;
  color: $BasicColor2;
  margin-bottom: 20px;
}
h2 {
  font-size: 2em;
  line-height: 1.3em;
  font-weight: 600;
  color: $BasicColor2;
  margin-bottom: 20px;
}
h3 {
  font-size: 1.8em;
  line-height: 1.3em;
  font-weight: 600;
  color: $BasicColor2;
  margin-bottom: 20px;
}
h4 {
  font-size: 1.2em;
  line-height: 1.3em;
  font-weight: 600;
  text-transform: uppercase;
  color: $BasicColor2;
  margin-bottom: 20px;
}
p {
  font-size: 1.2em;
  line-height: 1.6em;
  color: $BasicColor3;
  margin: 0px 0px 20px 0px;
}
ul {
  margin: 0px;
  padding: 0px 0px 10px 15px;
}
ul li {
  font-size: 1.3em;
  line-height: 1.6em;
  margin: 0px;
  padding: 0px 0px 10px 0px;
}
ol {
  margin: 0px;
  padding: 0px 0px 10px 15px;
}
ol li {
  font-size: 1.3em;
  line-height: 1.6em;
  margin: 0px;
  padding: 0px 0px 10px 0px;
}

.LineSpacer1 {
  height: 4px;
  font-size: 0.1rem;
  clear: both;
}
.LineSpacer2 {
  height: 5px;
  font-size: 0.1rem;
  clear: both;
}
.LineSpacer3 {
  height: 10px;
  font-size: 0.1rem;
  clear: both;
}
.LineSpacer4 {
  height: 15px;
  font-size: 0.1rem;
  clear: both;
}
.LineSpacer5 {
  height: 25px;
  font-size: 0.1rem;
  clear: both;
}
.LineSpacer6 {
  height: 40px;
  font-size: 0.1rem;
  clear: both;
}
.LineSpacer7 {
  height: 80px;
  font-size: 0.1rem;
  clear: both;
}
.LineSpacer8 {
  height: 200px;
  font-size: 0.1rem;
  clear: both;
}

.BottomFix {
  clear: both;
  height: 0px;
  font-size: 0px;
  line-height: 0px;
}

.WidthContent {
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
}
.HeightContent {
  padding-top: 60px;
  padding-bottom: 60px;
}

.InnerWidthContent {
  padding-left: 30px;
  padding-right: 30px;
}

.CategoryPage {
  min-width: calc(100% - #{$navWidth});
  flex: 1;
}

.CategoryPage .NoData {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  padding: 30px;
  img {
    height: 40px;
    margin-bottom: 20px;
  }
}

.LinkText {
  color: $MainColor1;
  text-decoration: underline;
  cursor: pointer;
}

// Spinner
.Spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(calc(#{$navWidth/2} - 50%), -50%);
  z-index: 10;
  border-radius: 100%;
  padding: 5px;
  background-color: white;

  &.PositionAbsolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.OptionsIcon {
  width: 50px;
  height: 50px !important;
  cursor: pointer;
}

.OptionsIcon.Hidden {
  display: none;
}

.GreenText {
  color: $GreenText;
  &.Bold {
    font-weight: bold;
  }
}

.RedText {
  color: $BasicColor5;
  &.Bold {
    font-weight: bold;
  }
}

/* Click detector */
.ClickOutsideDetector {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.MainHeader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  max-width: 100vw;
  width: 100%;
  background-color: $BasicColor1;
  border-bottom: 1px solid $BasicColor4;
  z-index: 10;
}

.MainHeader .Logo img {
  max-width: 160px;
  max-height: 40px;
}

.MainHeader .Venue {
  display: flex;
  flex-direction: column;
}

.MainHeader .Venue span {
  &:first-child {
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
  }

  &:last-child {
    text-align: center;
  }
}

.MainHeader .User {
  display: flex;
  align-items: center;

  span {
    font-size: 1.3em;
    font-weight: 600;
    margin-right: 10px;
  }

  .MenuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    color: $BasicColor1;
    background-color: $MainColor1;
    border-radius: 50%;
    cursor: pointer;

    span {
      margin: 0;
    }
  }
}

/* Mobile Header */
.MobileHeader {
  display: none;
  background: $BasicColor1;
  border-bottom: 1px solid $BasicColor4;
  width: 100%;
  height: 60px;
  z-index: 10;
  /*
  position: sticky;
  top: 0px;
  */
}
.MobileHeader .Logo {
  display: flex;
  flex: 1;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 60px;
}
.MobileHeader .Logo img {
  max-width: 160px;
  max-height: 40px;
}
.MobileHeader .BtnMobileNav {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60px;
  cursor: pointer;
}
.MobileHeader .BtnMobileNav img {
  width: 100%;
  display: block;
}

/*HeaderMenu*/
.HeaderMenu {
  position: fixed;
  top: 55px;
  right: 30px;
  height: fit-content;
  width: fit-content;
  min-width: 140px;
  background-color: $BasicColor5;
  border-radius: 3px;
  padding: 0;
  cursor: default;
  z-index: 101;

  ul {
    list-style: none;
    padding: 10px 0;
    color: $BasicColor2;
    li {
      font-size: 1.2em;
      padding: 0;

      a {
        text-decoration: none;
        color: inherit;
        display: block;
        width: 100%;
        cursor: pointer;
        padding: 10px 15px;

        &:hover {
          background-color: $MainColor2;
        }
      }
    }
    .ClickableItem {
      cursor: pointer;
      padding: 10px 15px;
    }
  }
}

/* Home */
.Home {
  margin: 25vh auto;
  text-align: center;
  font-size: 1.5em;
}
.Home img {
  vertical-align: -32%;
}

/* Page not found */
.NotFound {
  margin: 25vh auto;
  text-align: center;
}
.NotFound h1 {
  font-size: 3em;
}
.NotFound p {
  font-size: 2em;
}
.NotFound p span {
  font-weight: bold;
}

/* Forms */
.FormBox {
  margin-bottom: 20px;
  position: relative;

  .FormImage {
    max-height: 400px;
    max-width: 330px;
    margin-top: 15px;
    margin-left: 15px;
    cursor: pointer;
  }

  img:not(.FormImage) {
    position: absolute;
    top: 25px;
    right: 0;
    height: 40px;
    cursor: pointer;
  }
  .ImagePopout {
    position: fixed;
    top: 0;
    right: 0;
    background-color: $BasicColor2;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    .FormImage {
      max-width: 100%;
      max-height: 100%;
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.FormLabel {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  line-height: 1.3em;
  margin-bottom: 8px;
  color: $BasicColor2;
  font-weight: 400;
}
.FormBox .FormLabel.NoPaddingTop {
  padding-top: 0;
}
.FormSideBySide .FormField.Flex,
.FormField.Flex {
  display: flex;
  * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
.FormField.FlexCol {
  display: flex;
  flex-direction: column;
  *:not(.CustomSelect__indicators) {
    flex: 1;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.FormField .EditBox {
  font-size: 1.2em;
  line-height: 1.5em;
  width: 100%;
  padding: 10px 15px;
  background-color: $BasicColor5;
  border: 1px solid $BasicColor4;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: $Font1;
  color: $BasicColor2;
  -webkit-appearance: none;

  &:focus {
    background-color: $MainColor2;
    border: 1px solid $MainColor1;
  }

  &:disabled {
    opacity: 0.6;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 0.8rem;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 0.8rem;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 0.8rem;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-size: 0.8rem;
  }
  &::-ms-reveal {
    display: none;
  }
}

.FormField .react-datepicker-wrapper {
  width: 100%;
}

.FormLabel + .FormField .EditBox.ViewOnly {
  padding-top: 15px;
  padding-bottom: 0;
}

.ReadOnlyForm .FormSideBySide .FormLabel,
.ReadOnlyForm .FormSideBySide .FormLabel + .FormField .EditBox {
  padding: 0;
}

.FormSideBySide .FormField .EditBox.ViewOnly {
  margin-bottom: -5px;
}

.FormField .EditBox.ViewOnly {
  border: 0;
  background-color: transparent;
  color: $MainColor3;
  // cursor: default;
}

.DateFields {
  display: flex;
  & > *:first-child {
    margin-right: 10px;
  }
}

.FormField .DropDown {
  font-size: 1.2em;
  line-height: 1.25em;
  width: 100%;
  padding: 10px 15px;
  background-color: $BasicColor5;
  border: 1px solid $BasicColor4;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: $Font1;
  color: $BasicColor2;
  -webkit-appearance: none;
}
.FormField .TextArea {
  font-size: 1.2em;
  line-height: 1.25em;
  width: 100%;
  padding: 10px 15px;
  background-color: $BasicColor5;
  border: 1px solid $BasicColor4;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: $Font1;
  color: $BasicColor2;
  -webkit-appearance: none;
}

input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.CustomInput {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0 0 0 30px;
  height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2em;
  line-height: 1.3em;
  cursor: pointer;

  &.SmallPaddingLeft {
    padding-left: 20px;
  }
}
.CustomInput:before,
.CustomInput:after {
  position: absolute;
  top: 1px;
  left: 0;
  display: block;
  transition: all 0.3s ease-out;
  width: 18px;
  height: 18px;
}
.CustomInput:before {
  content: " ";
  border-radius: 3px;
  border: 1px solid $BasicColor4;
  background-color: $BasicColor1;
  box-sizing: border-box;
}
.FocusInputLabel {
  color: $MainColor1 !important;
}

.ErrorInput {
  border: 1px solid $AlertColor3 !important;
  background-color: $BasicColor6 !important;
}
.ErrorInputLabel {
  color: $AlertColor3 !important;
}
.ErrorLabel {
  color: $AlertColor3 !important;
  font-size: 1em;
  margin-top: 3px;
}
.ErrorMessage {
  color: $AlertColor3 !important;
  font-size: 1em;
  margin: 10px 0;
  text-align: center;
}

input[type="checkbox"] + .CustomInput:after {
  content: "";
  line-height: 20px;
  text-align: center;
  margin: 0;
  background-image: inherit;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: 50%;
}
input[type="radio"] + .CustomInput:after {
  content: "";
  line-height: 20px;
  text-align: center;
  margin: 0;
  background-image: inherit;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: 50%;
}
input[type="radio"] + .CustomInput:before {
  border-radius: 100%;
}
input[type="checkbox"]:checked + .CustomInput:before {
  background: $MainColor1;
  border: 1px solid $MainColor1;
}
input[type="radio"]:checked + .CustomInput:before {
  background: $MainColor1;
  border: 1px solid $MainColor1;
}
input[type="checkbox"]:checked + .CustomInput:after,
input[type="radio"]:checked + .CustomInput:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  background-image: url("../assets/Icon_Checklist.png");
}
/* Checked and disabled checkbox*/
input[type="checkbox"]:disabled + .CustomInput,
input[type="checkbox"]:disabled + .CustomInput:before,
input[type="checkbox"]:disabled + .CustomInput:after,
input[type="radio"]:disabled + .CustomInput:before,
input[type="radio"]:disabled + .CustomInput,
input[type="radio"]:disabled + .CustomInput:after {
  opacity: 0.5;
  cursor: default;
}
input[type="checkbox"]:disabled + .CustomInput:before,
input[type="checkbox"]:disabled + .CustomInput:after {
  transition: none;
}
input[type="checkbox"]:disabled + .CustomInput {
  cursor: default;
}
input[type="checkbox"]:checked:disabled + .CustomInput:before {
  border-radius: 100%;
}

input[type="checkbox"]:checked:disabled + .CustomInput:after {
  background-size: 25px;
}
/* Not checked and disabled checkbox*/
input[type="checkbox"]:not(:checked):disabled + .CustomInput:before {
  background: transparent;
  border: none;
}
input[type="checkbox"]:not(:checked):disabled + .CustomInput:after {
  background-image: url("../assets/Checkbox-min.png");
  background-size: 15px;
}

.FormSideBySide {
  display: table;
  width: 100%;
}
.FormSideBySide .FormLabel {
  display: table-cell;
  width: 175px;
  padding: 15px 5px 0px 0px;
  vertical-align: top;
}
.FormSideBySide .FormField {
  position: relative;
  display: table-cell;
}
.FormSideBySide .FieldView {
  display: table-cell;
  vertical-align: top;
}

.RadioButtons {
  display: flex;
  flex: 1;
  max-width: 300px;
}
.RadioButtons .Radio {
  margin-left: 20px;
}
.RadioButtons .Radio:first-child {
  margin-left: 0;
}
.RadioButtons .Radio label {
  padding-left: 25px;
}

.MultiChoice {
  padding: 15px;
  margin: 10px 0px 0 0;
  height: 100px;
  overflow: auto;
  background-color: $MainColor4;
  border: 1px solid $MainColor5;
}
.MultiChoice .Checkbox {
  margin-bottom: 10px;
}
.MultiChoice .Checkbox:last-child {
  margin-bottom: 0px;
}

.SignInConfig {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.SignInConfig .ForgottenPassword {
  font-size: 1.2em;
  color: $MainColor1;
  text-decoration: underline;
  cursor: pointer;
}

.UnderButtonLink {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.3em;
  text-decoration: underline;
  color: $MainColor1;
  cursor: pointer;
  margin: 5px;
}

/* Toasts */
.Toastify .Toastify__toast-container {
  font-size: 1rem;
  color: #f2f3f4;

  .Toastify__toast {
    border-radius: 3px;

    &.Toastify__toast--error {
      background-color: $BasicColor5;
    }
  }
}

/* Buttons */

.Buttons {
  display: flex;
  justify-content: stretch;
  & > button:not(:last-child) {
    margin-right: 8px;
  }
}

.Btn {
  display: inline-block;
  font-size: 1.3em;
  line-height: 1em;
  font-weight: 700;
  color: $BasicColor1;
  text-align: center;
  border: 1px solid $MainColor1;
  border-radius: 3px;
  box-sizing: border-box;
  background: $MainColor1;
  padding: 12px 30px;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  font-family: $Font1;
  opacity: 1;
  transition: opacity 0.3s ease-out;
  transform: translate3d(
    0,
    0,
    0
  ); // Addresses issue with chrome distorting buttons with opacity property.
}
.Btn.BtnAlt {
  border: 1px solid $MainColor1;
  background-color: transparent;
  color: $MainColor1;
}
.Btn:hover {
  opacity: 0.8;
}
.Btn:disabled {
  opacity: 0.4;
  cursor: auto;
}
.Btn:disabled:hover {
  opacity: 0.4;
}
.Btn.BtnSmall {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 400;
  padding: 10px 15px;
}
.Btn.BtnExtraSmall {
  font-size: 1em;
  line-height: 1em;
  font-weight: 400;
  padding: 5px 13px;
}
.Btn.BtnFull {
  display: block;
  width: 100%;
}

.Btn.BtnBlue {
  background: $MainColor1;
  border: 1px solid $MainColor1;
}
.Btn.BtnGreen {
  background: $AlertColor1;
  border: 1px solid $AlertColor1;
}

.Btn.BtnYellow {
  background: $BtnYellowColor;
  border: 1px solid $BtnYellowColor;
}
.Btn.BtnOutline {
  background: none;
  color: $MainColor1;
}

.Btn.BtnRed {
  background: $AlertColor3;
  border: 1px solid $AlertColor3;
}

.Btn.BtnWhite {
  background-color: $BasicColor1;
  color: $MainColor3;
  border: 1px solid $MainColor5;
  font-weight: 400;
}

.Btn.BtnGreyBorder {
  background: $BasicColor1;
  border: 1px solid $MainColor5;
  color: $BasicColor2;
}

.Btn.BtnGreyBorder:hover {
  background: $MainColor4;
  border: 1px solid $MainColor7;
}

.Btn.BtnCancel {
  background: inherit;
  border: 1px solid $MainColor5;
  color: $MainColor3;
  font-weight: 400;

  &.WithCrossIcon {
    background-image: url("../assets/Icon_Btn_06.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 50px;
  }
}
.Btn.BtnAdd {
  background: $MainColor8;
  border: 1px solid $MainColor8;
  color: $MainColor1;
  background-image: url("../assets/Icon_Btn_01.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 50px;
  white-space: nowrap;
}
.Btn.BtnSave {
  background: $MainColor1;
  border: 1px solid $MainColor1;
  color: $BasicColor1;
  background-image: url("../assets/Icon_Checklist.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 50px;
  white-space: nowrap;
}
.Btn.BtnUpdate {
  background: $MainColor1;
  border: 1px solid $MainColor1;
  color: $BasicColor1;
  background-image: url("../assets/Icon_Update_White.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 50px;
  white-space: nowrap;
}
.Btn.BtnUpdateInverted {
  background: $MainColor8;
  border: 1px solid $MainColor8;
  color: $MainColor1;
  background-image: url("../assets/Icon_Btn_02.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 50px;
  white-space: nowrap;
}
.Btn.BtnFilter {
  background: $MainColor8;
  border: 1px solid $MainColor8;
  color: $MainColor1;
  background-image: url("../assets/Icon_Filter.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 50px;
  white-space: nowrap;
}

.Btn.BtnPagination {
  font-weight: 400;
}

.Btn.IconOnly {
  height: 40px;
  padding: 0;
  width: 40px;
  background-color: transparent;
  border: none;
}

.MobileNavBackground {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $BasicColor2;
  opacity: 0.6;
  z-index: 1;
  transition: opacity 0.5s;
  &.Hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.Navigation .Inner {
  background: $BasicColor5;
  width: $navWidth;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  padding: 40px 0px 0px 0px;
  overflow-y: auto;
}
.Navigation .Inner .Block {
  display: flex;
  flex-direction: column;
  height: calc(100%);
}
.Navigation .Inner .Block .Logo {
  padding: 30px;
  text-align: center;
  cursor: pointer;
}
.Navigation .Inner .Block .Logo img {
  display: inline-block;
  width: 100%;
  max-width: 326px;
}
.Navigation .Inner .Block ul {
  padding: 0px;
  margin: 0 0 20px;
  flex: 0 1;
}
.Navigation .Inner .Block span {
  text-transform: uppercase;
  color: $BasicColor3;
  font-size: 1em;
  padding: 0 30px;
  border-left: 5px solid $BasicColor5;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.Navigation .Inner .Block .LineBreak {
  align-self: center;
  width: 80%;
  border-bottom: 1px solid $BasicColor4;
  margin-bottom: 30px;
  &:last-of-type {
    display: none;
  }
}
.Navigation .Inner .Block ul li {
  padding: 0px;
  margin: 0px;
  font-size: inherit;
}
.Navigation .Inner .Block ul li a {
  padding: 18.5px 30px;
  margin: 0px;
  color: $BasicColor2;
  font-weight: 600;
  font-size: 1.3em;
  line-height: 1em;
  text-decoration: none;
  text-transform: capitalize;
  width: 100%;
  display: block;
  box-sizing: border-box;
  border-left: 5px solid $BasicColor5;
}
.Navigation .Inner .Block ul li a:hover {
  color: $BasicColor2;
  background: $MainColor2;
  border-left: 5px solid $MainColor2;
}
.Navigation .Inner .Block ul li.Active a {
  color: $BasicColor2;
  background: $BasicColor1;
  border-left: 5px solid $MainColor1;
}
.Navigation .Inner .Block .BottomNav {
  padding: 30px 30px;
  width: 100%;
  display: block;
  box-sizing: border-box;
}
.Navigation .BtnMobileCross {
  display: none;
  justify-content: center;
}
.Navigation .BtnMobileCross img {
  display: block;
  width: 60px;
  border: 1px solid $MainColor2;
  border-radius: 60px;
  cursor: pointer;
  margin-bottom: 30px;
}

/* Login */
.LoginTable {
  display: table;
  width: 100%;
  background-color: $BasicColor1;
  height: calc(100vh - 120px);
}
.LoginTable .LoginCell {
  display: table-cell;
  vertical-align: middle;
}

.LoginForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  float: none;
  max-width: 400px;
  background-color: $BasicColor1;
  // overflow-y: scroll;
}

.LoginForm form {
  width: 100%;
}

.LoginForm .Logo {
  padding: 0px 0px 30px 0px;
  text-align: center;
}
.LoginForm .Logo img {
  display: inline-block;
  width: 100%;
  max-width: 326px;
}
.LoginForm .SignInErrorMessage {
  margin-bottom: 20px;
  text-align: center;
  color: $AlertColor3;
}

/* Error page */
.ErrorPage {
  display: table;
  width: 100%;
  background-color: $MainColor4;
  height: calc(100vh);
}
.ErrorPage .ErrorCell {
  display: table-cell;
  vertical-align: middle;
}
.ErrorPage .Img {
  display: block;
  text-align: center;
}
.ErrorPage .Img img {
  width: 50px;
  display: inline-block;
}
.ErrorPage .Txt {
  display: block;
  text-align: center;
  font-size: 1.3em;
  line-height: 1.6em;
  margin: 10px 0px 0px 0px;
}

/* Data Table */
.DataTable {
  font-size: 1.2em;
  line-height: 1em;
  color: $BasicColor2;
}
.DataTable .Head {
  position: sticky;
  top: 0px;
  background: $BasicColor1;
}
.DataTable .Head .Row .Cell button {
  font-size: 1em;
  line-height: 1em;
  color: $MainColor3;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: none;
  border: none;
  cursor: pointer;
}

.DataTable .Head .Row .Cell.CheckboxCell {
  flex: initial;
  width: 40px;
}

.DataTable .Head .Row .Cell.FilterCell {
  flex: initial;
  width: 60px;
  justify-content: flex-end;
}
.DataTable .Head .Row .Cell.FilterCell .FilterIcon {
  width: 60px;
  margin-right: -5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.DataTable .Head .Row .Cell.FilterCell .FilterIcon:hover {
  opacity: 0.6;
}
/*
.DataTable .Content {
}
*/
.DataTable .Row {
  display: flex;
  border-bottom: 1px solid $MainColor5;
  min-height: 60px;
}
.DataTable .Row.Highlighted {
  background: $MainColor8;
}
.DataTable .Row .Cell {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.1em;
}
.DataTable .Row .Cell.CheckboxCell {
  flex: initial;
  width: 40px;
  padding-left: 10px;
}
.DataTable .Row .Cell.OptionsCell {
  position: relative;
  overflow: visible;
  justify-content: flex-end;
  flex: initial;
  width: 60px;
}
.DataTable .Row .Cell.OptionsCell .Tag {
  padding-right: 0;
}
.DataTable .Row .Cell.OptionsCell .Tag img {
  padding-right: 0px;
  transition: all 0.2s ease-in-out;
}
.DataTable .Row .Cell.OptionsCell .Tag img:hover {
  opacity: 0.6;
}
.DataTable .Row .Cell .Tag {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
}

.DataTable .Row .Cell .Tag img {
  height: 15px;
  padding-right: 10px;
  vertical-align: text-bottom;
}

/* Table row option component */
.RowOptionContainer {
  position: relative;
  overflow: visible;
}
.OptionsList {
  position: absolute;
  top: 40px;
  z-index: 10;
  min-width: 150px;
  padding: 10px 0px;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: $BasicColor1;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  -webkit-box-shadow: 0px 0px 4px -2px $MainColor7;
  -moz-box-shadow: 0px 0px 4px -2px $MainColor7;
  box-shadow: 0px 0px 4px -2px $MainColor7;

  &.entering {
    display: flex;
  }

  &.entered {
    display: flex;
    opacity: 1;
  }

  &.exiting {
    display: flex;
    opacity: 0;
  }

  &.exited {
    display: none;
  }

  .OptionItem {
    padding: 10px 20px;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                      supported by Chrome, Opera and Firefox */

    // &:last-child {
    //   padding-bottom: 20px;
    // }

    &:hover {
      background: $MainColor8;
    }
  }
}

/* Page header */
.PageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.PageHeader .HeaderActionsContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 10px;
  }
}

/* Right Menu */
.RightMenu {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $BasicColor1;
  // border-left: 1px solid $fifthColor;
  width: 0;
  min-width: 0;
  overflow: hidden;
  visibility: hidden;
  transition: min-width 0.2s ease-in-out;
  white-space: nowrap;
  min-height: 100%;
}
.RightMenu.entered {
  min-width: 240px;
  visibility: visible;
  border-left: 1px solid $BasicColor4;
  box-sizing: border-box;
}
.RightMenu.exiting {
  visibility: visible;
}
.RightMenu.exited {
  visibility: hidden;
}
.RightMenu .CloseMenuButton {
  z-index: 5;
  cursor: pointer;
  margin-left: auto;
}
.RightMenu .ItemsContainer {
  position: fixed;
  padding: 60px 0 0 0px;
  width: 100%;
}
.RightMenu .ItemsContainer .Item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 0px 10px;
  width: 100%;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                              supported by Chrome, Opera and Firefox */
}
.RightMenu .ItemsContainer .Item:hover {
  background-color: $MainColor8;
}
.RightMenu .ItemsContainer .Item .Icon {
  width: 40px;
  margin-right: 10px;
}
.RightMenu .ItemsContainer .Item.Disabled {
  opacity: 0.4;
  cursor: default;
}
.RightMenu .ItemsContainer .Item.Disabled:hover {
  background-color: initial;
}

/* Right Panel */
.RightPanelUnderlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: 45;
}

.RightPanelUnderlay.entered {
  opacity: 1;
}

.RightPanel {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  min-width: 600px;
  background-color: $BasicColor1;
  border-left: 1px solid $MainColor5;
  border-radius: 0px;
  transform: translate3d(100vw, 0, 0);
  transition: all 0.2s ease-in-out;
  z-index: 50;
}

.RightPanel.entered {
  transform: translate3d(0, 0, 0);
}

.RightPanel .Top {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $MainColor5;
  box-sizing: border-box;
  height: 60px;
  padding: 10px 60px 10px 30px;

  &.BorderBottom {
    border-bottom: 1px solid $MainColor5 !important;
  }
}
.RightPanel .Top .Title {
  font-size: 2em;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  color: $MainColor3;
  text-transform: capitalize;
}
.RightPanel .Top .Cross {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  cursor: pointer;
}
.RightPanel .Top .Cross img {
  width: 100%;
  display: block;
}
/*
.RightPanel .Content {
}
*/
.RightPanel .Content .SubTitle {
  margin: 30px 0 20px 0;
}

.RightPanel .Form {
  padding: 40px 30px 20px 30px;
  height: calc(100vh - 120px);
  box-sizing: border-box;
  overflow-x: auto;
}

.RightPanel .Buttons {
  height: 60px;
  display: flex;
  align-items: center;
  background: $MainColor4;
  padding: 0px 30px;
  justify-content: flex-end;
}
.RightPanel .Buttons .Btn {
  margin: 0px 0px 0px 10px;
  min-width: 120px;
}

/* Modal */
.ModalUnderlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 45;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}
.ModalUnderlay.entering {
  display: flex;
}

.ModalUnderlay.entered {
  display: flex;
  opacity: 1;
}

.ModalUnderlay.exited {
  display: none;
  visibility: 0;
}

.ModalUnderlay .Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 3px;
  min-width: 320px;
  // overflow-y: auto;
}

//Full size modal. Used for Layout Config component
.ModalUnderlay .Modal.FullSize {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  max-height: initial;
  width: calc(100vw - 60px);
  height: calc(100vh - 60px);
}

.ModalUnderlay .Modal.FullSize .Inner {
  width: 100%;
}

.ModalUnderlay .Modal.FullSize .Content {
  box-sizing: border-box;
  height: calc(100vh - 180px);
}

.ModalUnderlay .Modal .Inner {
  position: relative;
  width: 450px;
}

.Modal .Top {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $MainColor5;
  box-sizing: border-box;
  height: 60px;
  padding: 10px 60px 10px 30px;
}
.Modal .Top .Title {
  font-size: 2em;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  color: $MainColor3;
  text-transform: capitalize;
}
.Modal .Top .Cross {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  cursor: pointer;
}
.Modal .Top .Cross img {
  width: 100%;
  display: block;
}

.Modal .Content {
  padding: 40px 30px 20px 30px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
}

.Modal .Content .DeleteWarning {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: $AlertColor4;
  border-radius: 3px;
  padding: 0 15px;

  p {
    color: $AlertColor3;
    margin-bottom: 0;
  }

  img {
    height: 20px;
    margin-right: 15px;
  }
}

.Modal .Buttons {
  height: 60px;
  display: flex;
  align-items: center;
  background: $MainColor4;
  padding: 0px 30px;
  justify-content: flex-end;
  border-radius: 0 0 3px 3px;
}
.Modal .Buttons .Btn {
  margin: 0px 0px 0px 10px;
  min-width: 120px;
}

/* Dropzone */
.Dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  border: 1px solid $MainColor5;
  background-color: $MainColor4;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.Dropzone.Disabled {
  cursor: default;
}
.Dropzone img {
  max-width: 100%;
  max-height: 100%;
}
.Dropzone img.Icon {
  width: 50px;
  margin-bottom: 10px;
}
.Dropzone p {
  margin: 0 0 5px 0 !important;
}
.Dropzone p.Bold {
  font-weight: 600;
}
.Dropzone .WarningMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  color: $BasicColor5;
}
.Dropzone img.WarningIcon {
  width: 30px;
}

.ImageInfo {
  font-size: 1rem;
  margin-top: 10px;
  color: $BasicColor2;
}

/* Colour Picker */

.ColourPicker {
  position: absolute;
  top: 40px;
  z-index: 1;

  .Btn {
    margin-top: 2px;
  }
}

.ColourSpot {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid $MainColor5;
  border-radius: 100%;
}

/** Search Box */
.SearchBoxContainer {
  position: relative;
  display: flex;
  width: 200px;
}

.SearchBoxContainer .SearchBox {
  font-size: 1.2em;
  width: 100%;
  padding: 10px 40px 10px 40px;
  background-color: $MainColor4;
  border: 1px solid $BasicColor4;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: $Font1;
  color: $BasicColor2;
  -webkit-appearance: none;
  background: url("../assets/Icon_Field_14.png");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  background-color: $BasicColor5;
}

.SearchBoxContainer .ClearSearchIcon {
  position: absolute;
  width: 35px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* ResultsPerPage dropdown  */

.ResultsPerPage {
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  // left: 0;
  min-width: 150px;
}

/* Password strength helper */
.PasswordStrengthHelper {
  display: flex;
  flex-direction: column;
}
.PasswordStrengthMeterContainer {
  margin: 3px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.PasswordStrengthInfo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $Colour_VeryWeak;
}
.PasswordStrengthInfo.Strength_Weak {
  color: $Colour_Weak;
}
.PasswordStrengthInfo.Strength_SoSo {
  color: $Colour_SoSo;
}
.PasswordStrengthInfo.Strength_Good {
  color: $Colour_Good;
}
.PasswordStrengthInfo.Strength_Great {
  color: $Colour_Great;
}

.PasswordStrengthInfo .MoreInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $BasicColor1;
  background-color: $MainColor1;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-left: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.PasswordStrengthInfo .MoreInfo:hover {
  opacity: 0.8;
}
.PasswordStrengthMeterContainer.Strength_VeryWeak .PasswordStrengthMeter {
  background-color: $Colour_VeryWeak;
}
.PasswordStrengthMeterContainer.Strength_Weak
  .PasswordStrengthMeter:first-child {
  background-color: $Colour_Weak;
}
.PasswordStrengthMeterContainer.Strength_SoSo
  .PasswordStrengthMeter:nth-child(-n + 2) {
  background-color: $Colour_SoSo;
}
.PasswordStrengthMeterContainer.Strength_Good
  .PasswordStrengthMeter:nth-child(-n + 3) {
  background-color: $Colour_Good;
}
.PasswordStrengthMeterContainer.Strength_Great .PasswordStrengthMeter {
  background-color: $Colour_Great;
}
.PasswordStrengthMeterContainer .PasswordStrengthMeter {
  width: 24%;
  height: 5px;
  background-color: $MainColor5;
  margin-right: 1%;
}
.PasswordStrengthMeterContainer .PasswordStrengthMeter:last-child {
  margin-right: 0;
}

.PasswordHelperMoreInfo {
  position: absolute;
  border: 1px solid $MainColor5;
  padding: 10px;
  border-radius: 3px;
  background-color: $BasicColor1;
  top: 0;
  right: 0;
  transform: translateX(115%);
}

/* PageFooter */
.PageFooter {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.FlexTableContainer {
  max-height: calc(100% - 140px);
  overflow-y: auto;
}

.ChangeSelectedTFALink {
  float: right;
  cursor: pointer;
  text-decoration: underline;
  color: $MainColor1;
  position: relative;
  z-index: 5;
}
.PhoneInput {
  margin-bottom: 20px;
  .PhoneInputCountry {
    padding: 10px;
    background-color: $BasicColor5;
    border: 1px solid $BasicColor4;
    border-radius: 3px;
    height: 40px;
  }
  .PhoneInputCountryIcon {
    border-radius: 3px;
    img {
      border-radius: 3px;
    }
  }
  .PhoneInputInput {
    padding: 10px 15px;
    background-color: $BasicColor5;
    border: 1px solid $BasicColor4;
    border-radius: 3px;
    height: 40px;
    color: $BasicColor2;
  }
}
.ErrorSection {
  height: 15px;
  padding: 2px 10px 1px 10px;
  line-height: 12px;
  background-color: $BgRedColor;
  color: $MainRedColor;
  font-size: 12px;
  margin-top: -15px;
  text-align: center;
}
