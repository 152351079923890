$Font1: "Open Sans", Arial, sans-serif;

$BasicColor1: #ffffff; /* white */
$BasicColor2: #333333; /* black */
$BasicColor3: #657478; /* grey */
$BasicColor4: #d5d5d5; /* grey for borders */
$BasicColor5: #f5f5f5; /* grey for backgrounds */
//
$BasicColor6: #feefee; /* light error red */

$MainColor1: #2b7ba0; /* blue use for link and button */
$MainColor2: #edf6f8; /* blue Grey use for text title */
//
$MainColor3: #757575; /* grey use for text */
$MainColor3a: #d2d2d2; /* lighter grey  */
$MainColor4: #f9f9fb; /* grey use for background field */
$MainColor5: #e1e5e8; /* grey use for border field */
$MainColor6: #37434f; /* grey use for background nav */
$MainColor7: #a7b0b9; /* grey use for text navigation */
$MainColor8: #e9f7f9; /* light green use for link and button */
$MainColor9: #9f9f9f;
$MainColor10: #1a1a1a;
$MainColor11: #f8f8f8;
$MainColor12: #da0a59;
$MainColor13: #283382;
$MainColor14: #818181;
$MainColor15: #d5d5d5;
$MainColor16: #657478;
$MainColor17: #333333;
$MainColor18: #d71a5b;
$MainColor19: #dfe1ec; /* dark blue background color*/
$MainColor20: #2df8fa;
$MainColor21: #78b86e;
$MainColor22: #d23a3a;
$MainRedColor: #d83838; /* red used for btn-red */
$BgRedColor: #c7797988;
$BtnYellowColor: #e08e00;

/* Password strength meter colours */
$Colour_VeryWeak: #e1e5e8; /* Very Weak: Grey */
$Colour_Weak: #d83838; /* Weak: Red */
$Colour_SoSo: #ffbf00; /* So-So: Amber */
$Colour_Good: #afc882; /* Good: Green */
$Colour_Great: #7cd838; /* Great: Green */

$AlertColor1: #84c779;
$AlertColor2: #f4fef2;
$AlertColor3: #c77979;
$AlertColor4: #fff7f7;
$AlertColor5: #388dcf;
$AlertColor6: #e3effb;

/* Misc */
$GreenText: rgb(56, 157, 45);

/* Navigation */
$navWidth: 240px;

@import "../pages/index.scss";
@import "../pages/Account/Account.scss";
@import "../components/pagination/pagination.scss";
@import "../components/newFlexTable/flexTable.scss";
@import "../components/dropdown/dropdown.scss";
@import "../components/Footer/Footer.scss";
@import "../components/SignIn/SignIn.scss";
@import "../components/KycComponents/CustomerViewPanel.scss";
@import "../pages/FrozenTransactions/FrozenTransactions.scss";
@import "../pages/venueApplications/venueApplication.scss";
@import "./General.scss";
@import "./MediaQueries.scss";
